import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {environment} from 'src/environments/environment';

import {NavigationService} from './services/navigation.service';
import {AuthFacade} from './store/auth-state/+state/auth.facade';
import {FiltersFacade} from './store/filters-store/+state/filters-data.facade';
import {MonitoringFacade} from './store/monitoring-store/+state/monitoring.facade';
import {
	Device,
	SocketDeviceInfo,
	VenueName
} from './store/monitoring-store/+state/monitoring.models';
import {Socket} from 'ngx-socket-io';
import {
	Subscription,
	combineLatest,
	debounceTime,
	distinctUntilChanged,
	interval,
	of,
	pairwise,
	switchMap
} from 'rxjs';
import {ToastrService} from 'ngx-toastr';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	public isLoggedIn$ = this.authFacade.isLoggedIn$;
	public userData$ = this.authFacade.userData$;
	intervalSubs: Subscription;
	intervalSubsStatistics: Subscription;
	public loadSocket = false;
	public listOfCollection: Map<string, SocketDeviceInfo> = new Map();
	constructor(
		private authFacade: AuthFacade,
		private filtersFacade: FiltersFacade,
		private navService: NavigationService,
		public translateService: TranslateService,

		private monitoringFacade: MonitoringFacade,
		public socket: Socket,
		private toaster: ToastrService
	) {
		console.log('Monitoring UI version -', environment.version);
		const favIcon: any =
			document.querySelector("link[rel*='icon']") ||
			document.createElement('link');
		favIcon.type = 'image/x-icon';
		favIcon.rel = 'shortcut icon';
		favIcon.href = '/assets/logo_white.svg';
		document.getElementsByTagName('head')[0].appendChild(favIcon);
		this.translateService.setDefaultLang('en');
		this.translateService.use('en');
		this.authFacade.checkToken();
		this.filtersFacade.loadCustomerGroup();
		this.isLoggedIn$.subscribe(v => {
			console.log('isLoggedIn', v);
		});
		this.userData$.subscribe(user => {
			if (user && user.venues.length > 0 && user.role != 'admin') {
				this.monitoringFacade.getVenueNames(user.venues);
			} else {
				if (user) this.monitoringFacade.getVenueNames([]);
			}
		});
		combineLatest(this.monitoringFacade.venueNames$, this.userData$)
			.pipe(debounceTime(300))
			.subscribe(([venueArray, userData]) => {
				console.log('HERE');
				if (venueArray?.length > 0 && userData) {
					if (userData.role === 'admin') {
						this.monitoringFacade.getDevices({venues: []});
						// this.monitoringFacade.getOnlineAndOrdersStatistic({
						// 	venues: venueArray.map(it => it._id)
						// });

						this.authenticateSocket(venueArray);
					} else {
						this.monitoringFacade.getDevices({
							venues: venueArray.map(it => it._id)
						});
						this.monitoringFacade.filters$.subscribe(v => {
							console.log('FILTERS', v);
						});
						this.monitoringFacade.getOnlineAndOrdersStatistic({
							venues: venueArray.map(it => it._id)
						});

						this.authenticateSocket(venueArray);
					}
					this.getDeviceStatiscs();
				}
			});
		this.monitoringFacade.devicesList$
			.pipe(
				pairwise(),
				switchMap(([prev, curr]) => {
					if (prev.length < curr.length) {
						return of(curr);
					} else {
						return of([]);
					}
				})
			)
			.subscribe(deviceList => {
				if (deviceList?.length > 0) {
					const groupData = this.groupArray(
						deviceList,
						50,
						deviceList.length
					) as any[][];
					let index = 0;
					// this.monitoringFacade.getTickets(
					// 	groupData[index]
					// 		.filter(it => it.deviceId != 'simphony-service')
					// 		.map(it => it.deviceId)
					// );
					// this.intervalSubs = interval(900).subscribe(v => {
					// 	index = v + 1;
					// 	if (groupData[index]) {
					// 		// this.monitoringFacade.getTickets(
					// 		// 	groupData[index]
					// 		// 		.filter(it => it.deviceId != 'simphony-service')
					// 		// 		.map(it => it.deviceId)
					// 		// );
					// 	} else {
					// 		this.unsubscribeInterval();
					// 	}
					// });
					this.listenDevicesStatusChangedSocket(deviceList);
				}
			});

		this.listenErrors();
		this.listenNotifications();
	}
	getDeviceStatiscs() {
		this.monitoringFacade.devicesList$
			.pipe(debounceTime(3000))
			.subscribe(deviceList => {
				if (deviceList.length === 0) {
					return;
				}

				const groupData = this.groupArray(
					deviceList,
					50,
					deviceList.length
				) as any[][];
				console.log('groupData', groupData);
				// this.monitoringFacade.getStatisticDevices({
				// 	deviceIds: deviceList.map(it => it.deviceId)
				// });
				// this.intervalSubsStatistics = interval(2000).subscribe(v => {
				// 	index = v + 1;
				// 	if (groupData[index]) {
				// 		this.monitoringFacade.getStatisticDevices({
				// 			deviceIds: groupData[index].map(it => it.deviceId)
				// 		});
				// 	} else {
				// 		this.unsubscribeIntervalSubsStatistics();
				// 	}
				// });
			});
	}
	logout() {
		this.authFacade.logout();
		this.monitoringFacade.reset();
		this.navService.login();
	}
	private unsubscribeInterval() {
		this.intervalSubs.unsubscribe();
	}
	private unsubscribeIntervalSubsStatistics() {
		this.intervalSubsStatistics.unsubscribe();
	}
	private listenDevicesStatusChangedSocket(deviceList: Device[]) {
		const mappedIds = deviceList.map(it => it._id);
		this.socket.on(
			`deviceStatus`,
			(socketId: string, data: SocketDeviceInfo) => {
				if (mappedIds.includes(data?.deviceId)) {
					this.listOfCollection.set(data.deviceId, data);
					if (this.listOfCollection.size > 20) {
						this.monitoringFacade.updateDeviceSocketEventInStoreCollection(
							this.listOfCollection
						);
						this.listOfCollection.clear();
					}
				}
			}
		);
	}

	private authenticateSocket(venueArray: VenueName[]): void {
		if (this.loadSocket) {
			return;
		}
		this.loadSocket = true;

		this.socket.emit(
			'authenticate',
			[environment.socketRole],
			venueArray.flatMap(it => [it._id, it.readableId]),
			(ack: any) => {
				console.log('Socket authenticated for ', ack, venueArray);
			}
		);
	}
	private groupArray(group, size, length) {
		return group
			.reduce(
				(accumulator, current, index, original) =>
					index % size == 0
						? accumulator.concat([original.slice(index, index + size)])
						: accumulator,
				[]
			)
			.filter((single, index) => index < length);
	}
	private listenNotifications() {
		this.monitoringFacade.selectedStatusDeviceFilter$
			.pipe(distinctUntilChanged(), pairwise())
			.subscribe(([prev, curr]) => {
				if (prev && !curr) {
					this.toaster.info('Status: ' + prev + ' was removed.', '', {
						timeOut: 3000
					});
				}
				if (curr) {
					this.toaster.info('Status: ' + curr + ' was added.', '', {
						timeOut: 3000
					});
				}
			});
		this.monitoringFacade.selectedStatusTicketFilter$
			.pipe(distinctUntilChanged(), pairwise())
			.subscribe(([prev, curr]) => {
				if (prev && !curr) {
					this.toaster.info(
						'Tickets with status: ' + prev + ' was removed.',
						'',
						{
							timeOut: 3000
						}
					);
				}
				if (curr) {
					this.toaster.info(
						'Tickets with status: ' + curr + ' was added.',
						'',
						{
							timeOut: 3000
						}
					);
				}
			});
	}
	private listenErrors() {
		this.authFacade.error$.subscribe((error: any) => {
			console.log(error);
			if (error) {
				this.toaster.error(
					error.url +
						' ' +
						'STATUS:' +
						error.status +
						' ' +
						'MESSAGE:' +
						error?.error?.message,
					'Backend error',
					{
						timeOut: 5000
					}
				);
			}
		});
		this.monitoringFacade.error$.subscribe((error: any) => {
			console.log(error);

			if (error) {
				this.toaster.error(
					error.url +
						' ' +
						'STATUS:' +
						error.status +
						' ' +
						'MESSAGE:' +
						error?.error?.message ?? error?.message,
					'Backend error',
					{
						timeOut: 5000
					}
				);
			}
		});
	}
}
