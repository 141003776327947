import {NgModule} from '@angular/core';
import {NoPreloading, RouterModule, Routes} from '@angular/router';
import {LoginPage} from './pages/auth/login/login.page';
import {AuthGuard} from './guards/auth.guard';
import {NAVIGATIONURLS} from './services/navigation.service';

const routes: Routes = [
	{
		path: '',
		redirectTo: NAVIGATIONURLS.monitoring(),
		pathMatch: 'full'
	},
	{
		path: NAVIGATIONURLS.monitoring(),
		loadChildren: () =>
			import('./pages/monitoring/monitoring.module').then(
				m => m.MonitoringModule
			),
		canActivate: [AuthGuard]
	},
	{
		path: NAVIGATIONURLS.monitoringTableURL(),
		loadChildren: () =>
			import('./pages/monitoring-table/monitoring-table.module').then(
				m => m.MonitoringTableModule
			),
		canActivate: [AuthGuard]
	},
	{
		path: NAVIGATIONURLS.salesStats(),
		loadChildren: () =>
			import('./pages/sales-stats/sales-stats.module').then(
				m => m.SalesStatsModule
			),
		canActivate: [AuthGuard]
	},
	{
		path: NAVIGATIONURLS.issueReports(),
		loadChildren: () =>
			import('./pages/issue-report/issue-report.module').then(
				m => m.IssueReportPageModule
			),
		canActivate: [AuthGuard]
	},
	{
		path: NAVIGATIONURLS.venues(),
		loadChildren: () =>
			import('./pages/venues-page/venues.module').then(m => m.VenuesModule),
		canActivate: [AuthGuard]
	},
	{
		path: NAVIGATIONURLS.createAndEditVenue(),
		loadChildren: () =>
			import('./pages/create-and-edit-venue/create-and-edit-venue.module').then(
				m => m.CreateAndEditVenueModule
			),
		canActivate: [AuthGuard]
	},
	{
		path: NAVIGATIONURLS.analytics(),
		loadChildren: () =>
			import('./pages/analytics/analytics.module').then(m => m.AnalyticsModule),
		canActivate: [AuthGuard]
	},
	{
		path: NAVIGATIONURLS.login(),
		component: LoginPage
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {preloadingStrategy: NoPreloading})],
	exports: [RouterModule]
})
export class AppRoutingModule {}
