import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core';
import {Observable} from 'rxjs';

import {User} from 'src/app/models/User';
import {AuthService} from 'src/app/services/auth.service';
import {
	NAVIGATIONURLS,
	NavigationService
} from 'src/app/services/navigation.service';
import {TokenService} from 'src/app/services/token.service';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent {
	@Input() user: User | null;
	@Output() logout = new EventEmitter<void>();

	public NAVIGATIONURLS = NAVIGATIONURLS;

	constructor(
		private authService: AuthService,
		private navService: NavigationService
	) {}
	// user$: Observable<User | null> = this.authService.user$;
	public sidebarItems = [
		{
			title: 'Operational',
			img: '../../../assets/sidebar_icons/operational.svg',
			link: `/${NAVIGATIONURLS.monitoring()}`
		},
		// {
		// 	title: 'Sales Stats',
		// 	img: '../../../assets/sidebar_icons/sales_stats.svg',
		// 	link: `/${NAVIGATIONURLS.salesStats()}`
		// },
		{
			title: 'Issue Reports',
			img: '../../../assets/sidebar_icons/sales_stats.svg',
			link: `/${NAVIGATIONURLS.issueReports()}`
		},
		{
			title: 'Analytics',
			img: '../../../assets/sidebar_icons/sales_stats.svg',
			link: `/${NAVIGATIONURLS.analytics()}`
		}
	];

	logoutFunc() {
		this.logout.emit();
	}
}
