import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {User} from 'src/app/models/User';
import {
	AnalyticsResponse,
	Device,
	DeviceFiltersParams,
	DeviceTypeEnum,
	getOnlineAndOrdersStatistic,
	OrderLogHistory,
	Ticket,
	UptimeStatisticResponse,
	VenueName
} from './monitoring.models';

import * as moment from 'moment';

@Injectable({
	providedIn: 'root'
})
export class MonitoringService {
	AUTH_URL = '/v1/auth';
	GENERAL_URL = '/v1/general';
	MONITORING_URL = '/v1/monitoring';

	constructor(private http: HttpClient) {}

	getDevicesWithFilters(
		filters: DeviceFiltersParams | null
	): Observable<Device[]> {
		let params = new HttpParams({
			fromObject: {}
		});

		if (filters) {
			if (filters?.customerGroups && filters?.customerGroups?.length > 0) {
				params = params.append(
					'customerGroup',
					filters?.customerGroups.join(',')
				);
			}
			if (filters?.venues && filters?.venues?.length > 0) {
				filters.venues.forEach((id: string) => {
					params = params.append(`venues[]`, id.toString());
				});
			}
			if (filters?.deviceIds && filters?.deviceIds?.length > 0) {
				filters.deviceIds.forEach((id: string) => {
					params = params.append(`deviceIds[]`, id.toString());
				});
			}
			if (filters?.statuses && filters?.statuses?.length > 0) {
				params = params.append('statuses', filters?.statuses.join(','));
			}
			if (filters?.search && filters?.search.length > 0) {
				params = params.append('search', filters.search.join(''));
			}
		}
		return this.http.get<Device[]>(
			`${environment.apiUrl}${this.MONITORING_URL}/device/filter`,
			{params}
		);
	}

	checkToken(refreshToken: string) {
		return this.http.post<User>(
			`${environment.apiUrl}${this.AUTH_URL}/refresh`,
			{
				refreshToken
			}
		);
	}

	getVenuesName(venueIds: string[]) {
		return this.http.post<VenueName[]>(
			`${environment.apiUrl}${this.GENERAL_URL}/venue/venuenames`,
			{
				venues: venueIds
			}
		);
	}
	deleteDevice(deviceId: string) {
		return this.http.delete<VenueName[]>(
			`${environment.apiUrl}${this.MONITORING_URL}/device/${deviceId}`
		);
	}
	getDeviceStatistic(filters: {
		from?: string;
		to?: string;
		deviceIds: string[];
	}): Observable<UptimeStatisticResponse[]> {
		let params = new HttpParams({
			fromObject: {
				from: moment(new Date())
					.subtract(1, 'd')
					.format('YYYY-MM-DD')
					.toString(),
				to: moment(new Date()).format('YYYY-MM-DD').toString()
			}
		});
		if (filters?.deviceIds && filters?.deviceIds?.length > 0) {
			filters.deviceIds.forEach((id: string) => {
				if (id) params = params.append(`deviceIds[]`, id.toString());
			});
		}
		return this.http.get<UptimeStatisticResponse[]>(
			`${environment.apiUrl}${this.MONITORING_URL}/device-status/getUptimeStatistic`,
			{params}
		);
	}

	getTickets(deviceIds: string[]) {
		let params = new HttpParams({
			fromObject: {}
		});
		if (deviceIds && deviceIds?.length > 0) {
			deviceIds.forEach((id: string) => {
				if (id) params = params.append(`deviceIds[]`, id.toString());
			});
		}
		return this.http.get<Ticket[]>(
			`${environment.apiUrl}${this.MONITORING_URL}/ticket/bydevices`,
			{params}
		);
	}

	createTicket(data: {
		title: string;
		message: string;
		deviceId: string;
		venue: string;
		author: {
			name: string;
			email?: string;
			phone?: string;
		};
	}) {
		return this.http.post<Ticket>(
			`${environment.apiUrl}${this.MONITORING_URL}/ticket`,
			data
		);
	}
	loadTicketWithConversation(data: {ticketId: string}) {
		return this.http.get<Ticket>(
			`${environment.apiUrl}${this.MONITORING_URL}/ticket/${data.ticketId}`
		);
	}
	sendMessageInConversation(
		ticketId: string,
		message: {
			name: string;
			message: string;
		}
	) {
		return this.http.post<Ticket>(
			`${environment.apiUrl}${this.MONITORING_URL}/ticket/message/${ticketId}`,
			message
		);
	}
	updateTIcket(ticketId: string, status: string) {
		return this.http.patch<Ticket>(
			`${environment.apiUrl}${this.MONITORING_URL}/ticket/${ticketId}`,
			{
				resolveStatus: status
			}
		);
	}

	loadDeviceById(deviceId: string) {
		return this.http.get<Device>(
			`${environment.apiUrl}${this.MONITORING_URL}/device/${deviceId}`
		);
	}
	loadStatisticForDeviceWithLogs(
		deviceId: string,
		search?: string,
		period?: {from: string; to: string}
	) {
		console.log('loadStatisticForDeviceWithLogs', deviceId, period);
		//checking if from to exist if not defualt weekly
		let from = moment(new Date())
			.subtract(1, 'd')
			.format('YYYY-MM-DD')
			.toString();
		let to = moment(new Date()).add(1, 'd').format('YYYY-MM-DD').toString();
		if (period?.from) {
			from = moment(period?.from)
				.format('YYYY-MM-DD')
				.toString();
		}
		if (period?.to) {
			to = moment(period?.to)
				.add(1, 'd')
				.format('YYYY-MM-DD')
				.toString();
		}

		let params = new HttpParams({
			fromObject: {
				from: from,
				to: to
			}
		});
		if (deviceId) {
			params = params.append(`deviceIds[]`, deviceId.toString());
		}
		console.log(search);
		if (search) {
			params = params.append(`search`, search.toString());
		}
		return this.http.get<any>(
			`${environment.apiUrl}${this.MONITORING_URL}/log/filter?sort=desc`,
			{params}
		);
	}
	getAnalyticsForDevice(
		deviceIds: string[],
		fromData?: string,
		toData?: string,
		page?: number,
		limit?: number
	) {
		//checking if from to exist if not defualt weekly
		let from = moment(new Date())
			.subtract(7, 'd')
			.format('YYYY-MM-DD')
			.toString();
		let to = moment(new Date()).add(1, 'd').format('YYYY-MM-DD').toString();
		if (fromData) {
			from = moment(fromData).format('YYYY-MM-DD').toString();
		}
		if (toData) {
			to = moment(toData).add(1, 'd').format('YYYY-MM-DD').toString();
		}
		let params = new HttpParams({
			fromObject: {
				from: from,
				to: to,
				page: page ?? 1,
				limit: limit ?? 10
			}
		});
		if (deviceIds && deviceIds?.length > 0) {
			deviceIds.forEach((id: string) => {
				if (id) params = params.append(`deviceIds[]`, id.toString());
			});
		}

		return this.http.get<AnalyticsResponse>(
			`${environment.apiUrl}${this.MONITORING_URL}/analytic/paginate?sort=asc`,
			{params}
		);
	}
	getAllAnalyticsByDevice(data: {
		from: string;
		to: string;
		venue: string;
		deviceNumber: string;
		deviceId: string;
		page: number;
		limit: number;
	}) {
		let from = moment(new Date()).subtract(7, 'd').format('YYYY-MM-DD');

		let to = moment(new Date()).add(1, 'd').format('YYYY-MM-DD').toString();
		if (data.from) {
			from = moment(data.from).toString();
		}
		if (data.to) {
			to = moment(data.to).toString();
		}
		let params = new HttpParams({
			fromObject: {
				from: from,
				to: to,
				deviceId: data.deviceId,
				limit: data.limit,
				page: data.page
			}
		});
		if (data.deviceNumber) {
			params = params.append(`deviceNumber`, data.deviceNumber.toString());
		}
		if (data.venue) {
			params = params.append('venue', data.venue);
		}
		return this.http.get<any>(
			`${environment.apiUrl}${this.MONITORING_URL}/device/history`,
			{params}
		);
	}
	getOrdersByDevice(data: {
		venueId: string;
		deviceNumber: string;
		deviceId?: string;

		from?: string;
		to?: string;
	}) {
		let from = moment(new Date()).subtract(7, 'd').format('YYYY-MM-DD');

		let to = moment(new Date()).add(1, 'd').format('YYYY-MM-DD').toString();
		if (data.from) {
			from = moment(data.from).toString();
		}
		if (data.to) {
			to = moment(data.to).toString();
		}
		let params = new HttpParams({
			fromObject: {
				from: from,
				to: to
			}
		});

		if (data.venueId) {
			params = params.append(`venues[]`, data.venueId.toString());
		}
		if (data.deviceNumber) {
			params = params.append(`deviceNumbers[]`, data.deviceNumber.toString());
		}

		return this.http.get<OrderLogHistory[]>(
			`${environment.apiUrl}${this.MONITORING_URL}/order-log/filter`,
			{params}
		);
	}
	getAllOrdersForDevices(data: {deviceId: string; from?: string; to?: string}) {
		let from = moment(new Date()).subtract(7, 'd').format('YYYY-MM-DD');

		let to = moment(new Date()).add(1, 'd').format('YYYY-MM-DD').toString();
		if (data.from) {
			from = moment(data.from).toString();
		}
		if (data.to) {
			to = moment(data.to).toString();
		}
		let params = new HttpParams({
			fromObject: {
				from: from,
				to: to
			}
		});

		if (data.deviceId) {
			params = params.append(`deviceId`, data.deviceId.toString());
		}
		return this.http.get<OrderLogHistory[]>(
			`${environment.apiUrl}${this.MONITORING_URL}/order-log/revenue`,
			{params}
		);
	}
	getOnlineAndOrdersStatistic(venueIds: string[], clean: boolean) {
		let params = new HttpParams({
			fromObject: {}
		});

		if (venueIds?.length > 0) {
			venueIds.forEach((id: string) => {
				if (id) params = params.append(`venues[]`, id.toString());
			});
		}

		return this.http.post<getOnlineAndOrdersStatistic>(
			`${environment.apiUrl}${this.MONITORING_URL}/device-status/getHeaderStats`,
			{
				venues: venueIds,
				cleanUptimeStatistic: clean
			}
		);
	}

	deleteTicketSoft(ticketId: string) {
		return this.http.delete<any>(
			`${environment.apiUrl}${this.MONITORING_URL}/ticket/soft/` + ticketId
		);
	}
	deleteTicketHard(ticketId: string) {
		return this.http.delete<any>(
			`${environment.apiUrl}${this.MONITORING_URL}/ticket/` + ticketId
		);
	}
	restoreTicket(ticket: Ticket) {
		return this.http.patch<any>(
			`${environment.apiUrl}${this.MONITORING_URL}/ticket/restore/` +
				ticket._id,
			{}
		);
	}
	updateDeviceType(data: {deviceId: string; deviceType: DeviceTypeEnum}) {
		return this.http.patch<Device>(
			`${environment.apiUrl}${this.MONITORING_URL}/device/` + data.deviceId,
			{
				deviceType: data.deviceType
			}
		);
	}
}
