import {APP_INITIALIZER, Injector, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppRoutingModule} from './app-routing.module';

import {
	HTTP_INTERCEPTORS,
	HttpClient,
	HttpClientModule
} from '@angular/common/http';
import {SocketIoConfig, SocketIoModule} from 'ngx-socket-io';
import {SocketService} from './services/socket.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoginPage} from './pages/auth/login/login.page';
import {AuthInterceptor} from './interceptors/onRequest';
import {ToolbarComponent} from './components/toolbar/toolbar.component';
import {SidebarComponent} from './components/sidebar/sidebar.component';

import {HighchartsChartModule} from 'highcharts-angular';
import {
	TranslateLoader,
	TranslateModule,
	TranslateService
} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {UserPinComponent} from './components/user-pin/user-pin.component';
import {LOCATION_INITIALIZED, registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import {NgSelectModule} from '@ng-select/ng-select';

import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {clearState} from './global-clear-state.reducer';
import {AuthStateModule} from './store/auth-state/+state/auth-state.module';
import {FiltersStateModule} from './store/filters-store/+state/filters-data-state.module';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';

import {environment} from '../environments/environment';
import {MonitoringStateModule} from './store/monitoring-store/+state/monitoring-state.module';
import {ToastrModule} from 'ngx-toastr';
import {MonitoringPageDeviceStateModule} from './store/monitoring-device-page-store/+state/monitoring-device-page-state.module';
import {AppComponent} from './app.component';

const config: SocketIoConfig = {
	url: environment.apiUrl,
	options: {
		path: '/v1/socketio/socket.io/'
	}
};

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

export function appInitializerFactory(
	translate: TranslateService,
	injector: Injector
) {
	return () =>
		new Promise<any>((resolve: any) => {
			const locationInitialized = injector.get(
				LOCATION_INITIALIZED,
				Promise.resolve(null)
			);
			locationInitialized.then(() => {
				translate.use(translate.defaultLang).subscribe({
					next: () => {},
					error: () => {},
					complete: () => resolve(null)
				});
			});
		});
}
export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

@NgModule({
	declarations: [
		AppComponent,
		LoginPage,
		ToolbarComponent,
		SidebarComponent,
		UserPinComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,

		AppRoutingModule,
		SocketIoModule.forRoot(config),
		FormsModule,
		ReactiveFormsModule,
		HighchartsChartModule,
		NgSelectModule,
		AuthStateModule,
		MonitoringStateModule,
		MonitoringPageDeviceStateModule,
		FiltersStateModule,
		NgxSkeletonLoaderModule.forRoot({animation: 'pulse'}),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient]
			},
			defaultLanguage: 'en'
		}),
		StoreModule.forRoot(
			{},
			{
				metaReducers: [clearState],
				runtimeChecks: {
					strictActionImmutability: true,
					strictStateImmutability: true
				}
			}
		),
		EffectsModule.forRoot([]),
		StoreDevtoolsModule.instrument({connectInZone: true}),
		ToastrModule.forRoot({
			positionClass: 'toast-bottom-right',
			preventDuplicates: true
		})
	],
	providers: [
		SocketService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true
		},
		{
			provide: APP_INITIALIZER,
			useFactory: appInitializerFactory,
			deps: [TranslateService, Injector],
			multi: true
		},
		{provide: LOCALE_ID, useValue: 'en-EN'}
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
