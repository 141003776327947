import {Injectable} from '@angular/core';
import {createEffect, Actions, ofType, act} from '@ngrx/effects';
import * as MonitoringActions from './monitoring.actions';

import {
	map,
	tap,
	switchMap,
	catchError,
	withLatestFrom,
	concatMap
} from 'rxjs/operators';
import {forkJoin, Observable, of} from 'rxjs';

import {MonitoringService} from './monitoring.service';
import {MonitoringFacade} from './monitoring.facade';
import {UptimeStatisticResponse} from './monitoring.models';

@Injectable()
export class MonitoringEffects {
	getDevices$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MonitoringActions.getDevices),
			withLatestFrom(this.monitoringFacade.filters$),
			concatMap(([data, filters]) => {
				filters = {
					...filters,
					venues: data?.venues ?? []
				};
				return this.monitoringService.getDevicesWithFilters(filters).pipe(
					map(response => {
						return MonitoringActions.getDevicesSuccess({response});
					}),
					catchError((error: any) => {
						return of(MonitoringActions.getDevicesFailed({error}));
					})
				);
			})
		)
	);
	deleteDevice$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MonitoringActions.deleteDevice),
			concatMap(params => {
				console.log(params);

				return this.monitoringService.deleteDevice(params.deviceId).pipe(
					map(response => {
						return MonitoringActions.deleteDeviceSucc({
							deviceId: params.deviceId
						});
					}),
					catchError((error: any) => {
						return of(
							MonitoringActions.deleteDeviceSucc({deviceId: params.deviceId})
						);
					})
				);
			})
		)
	);
	deleteTicketSoft$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MonitoringActions.deleteTicketSoft),
			concatMap(params => {
				console.log(params);

				return this.monitoringService.deleteTicketSoft(params.ticketId).pipe(
					map(response => {
						console.log(response);
						return MonitoringActions.deleteTicketSoftSucc({
							ticketId: params.ticketId
						});
					}),
					catchError((error: any) => {
						console.log(error);
						return of(
							MonitoringActions.deleteTicketSoftSucc({
								ticketId: params.ticketId
							})
						);
					})
				);
			})
		)
	);
	deleteTicketHard$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MonitoringActions.deleteTicketHard),
			concatMap(params => {
				console.log(params);

				return this.monitoringService.deleteTicketHard(params.ticketId).pipe(
					map(response => {
						console.log(response);
						return MonitoringActions.deleteTicketHardSucc({
							ticketId: params.ticketId
						});
					}),
					catchError((error: any) => {
						console.log(error);
						return of(
							MonitoringActions.deleteTicketHardSucc({
								ticketId: params.ticketId
							})
						);
					})
				);
			})
		)
	);
	restoreTicket$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MonitoringActions.restoreTicket),
			concatMap(params => {
				console.log(params);

				return this.monitoringService.restoreTicket(params.ticket).pipe(
					map(response => {
						console.log(response);
						return MonitoringActions.restoreTicketSucc({
							ticketId: params.ticket._id
						});
					}),
					catchError((error: any) => {
						console.log(error);
						return of(
							MonitoringActions.restoreTicketSucc({
								ticketId: params.ticket._id
							})
						);
					})
				);
			})
		)
	);
	createTicket$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MonitoringActions.createTicket),
			concatMap(value => {
				return this.monitoringService.createTicket(value).pipe(
					map(response => {
						return MonitoringActions.createTicketSuccesfull({response});
					}),
					catchError((error: any) => {
						return of(MonitoringActions.createTicketFailed({error}));
					})
				);
			})
		)
	);

	sendMessageInConversation$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MonitoringActions.sendMessageInConveration),
			concatMap(value => {
				return this.monitoringService
					.sendMessageInConversation(value.ticketId, value.message)
					.pipe(
						map(response => {
							return MonitoringActions.sendMessageInConverationSuccessfull({
								response
							});
						}),
						catchError((error: any) => {
							return of(
								MonitoringActions.sendMessageInConverationFailed({error})
							);
						})
					);
			})
		)
	);

	updateTIcket$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MonitoringActions.updateTicket),
			concatMap(value => {
				return this.monitoringService
					.updateTIcket(value.ticketId, value.status)
					.pipe(
						map(response => {
							return MonitoringActions.updateTicketSucc({
								response
							});
						}),
						catchError((error: any) => {
							return of(MonitoringActions.updateTicketFailed({error}));
						})
					);
			})
		)
	);

	loadTicketWithConversation$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MonitoringActions.loadTicketWithConversation),
			concatMap(value => {
				return this.monitoringService.loadTicketWithConversation(value).pipe(
					map(response => {
						return MonitoringActions.loadTicketWithConversationSuccessfull({
							response: response
						});
					}),
					catchError((error: any) => {
						return of(
							MonitoringActions.loadTicketWithConversationFailed({error})
						);
					})
				);
			})
		)
	);
	getTickets$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MonitoringActions.getTickets),
			switchMap(response => {
				return this.monitoringService.getTickets(response.deviceIds).pipe(
					map(response => {
						return MonitoringActions.getTicketsSuccesfull({
							response: response
						});
					}),
					catchError(error => {
						return of(MonitoringActions.getTicketsFailed({error}));
					})
				);
			})
		)
	);
	getVenueNames$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MonitoringActions.getVenueNames),
			switchMap(venueIds => {
				return this.monitoringService.getVenuesName(venueIds.venues).pipe(
					map(response => {
						return MonitoringActions.getVenueNamesSuccess({
							response: response
						});
					}),
					catchError(error => {
						return of(MonitoringActions.getVenueNamesFailed({error}));
					})
				);
			})
		)
	);
	// getStatistic$ = createEffect(() =>
	// 	this.actions$.pipe(
	// 		ofType(MonitoringActions.getStatistic),

	// 		switchMap(filters => {
	// 			console.log('getStat', filters);
	// 			return this.monitoringService.getDeviceStatistic(filters).pipe(
	// 				map(response => {
	// 					return MonitoringActions.getStatisticSuccess({response});
	// 				}),
	// 				catchError(error => {
	// 					return of(MonitoringActions.getStatisticFailed({error}));
	// 				})
	// 			);
	// 		})
	// 	)
	// );

	getStatisticSecond$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MonitoringActions.getStatistic),
			switchMap(action => {
				const chunkSize = 100;
				const deviceIdChunks = action.deviceIds
					.reduce(
						(accumulator, current, index, original) =>
							index % chunkSize == 0
								? (accumulator as any).concat([
										original.slice(index, index + chunkSize)
								  ])
								: accumulator,
						[]
					)
					.filter((single, index) => index < action.deviceIds.length);
				const arrayOfObservables: Observable<UptimeStatisticResponse[]>[] =
					deviceIdChunks.map(it =>
						this.monitoringService.getDeviceStatistic({
							deviceIds: it,
							from: action.from,
							to: action.to
						})
					);
				return forkJoin(arrayOfObservables).pipe(
					map((response: UptimeStatisticResponse[][]) => {
						return MonitoringActions.getStatisticSuccess({
							response: response.flat()
						});
					}),
					catchError(error => of(MonitoringActions.getStatisticFailed({error})))
				);
			})
		)
	);

	// reloadDevicesByFilters$ = createEffect(
	// 	() => {
	// 		return this.actions$.pipe(
	// 			ofType(MonitoringActions.setFiltersDevices),
	// 			tap(() => {
	// 				this.monitoringFacade.getDevices();
	// 			})
	// 		);
	// 	},
	// 	{dispatch: false}
	// );

	getStatisticWithLogForDevice$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MonitoringActions.loadStatiscticForDevice),

			switchMap(filters => {
				console.log(filters);
				return this.monitoringService
					.loadStatisticForDeviceWithLogs(
						filters.deviceId,
						filters?.search,
						filters?.period
					)
					.pipe(
						map(response => {
							return MonitoringActions.loadStatiscticForDeviceSucc({
								response
							});
						}),
						catchError(error => {
							return of(
								MonitoringActions.loadStatiscticForDeviceFailed({error})
							);
						})
					);
			})
		)
	);
	getDeviceById$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MonitoringActions.loadDeviceById),

			switchMap(filters => {
				return this.monitoringService.loadDeviceById(filters.deviceId).pipe(
					map(response => {
						return MonitoringActions.loadDeviceByIdSucc({
							response
						});
					}),
					catchError(error => {
						return of(MonitoringActions.loadDeviceByIdFailed({error}));
					})
				);
			})
		)
	);
	getVenueById$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MonitoringActions.loadVenue),
			switchMap(result => {
				return this.monitoringService.getVenuesName([result.venueId]).pipe(
					map(response => {
						return MonitoringActions.loadVenueSucc({
							response: response[0]
						});
					}),
					catchError(error => {
						return of(MonitoringActions.loadVenueFailed({error}));
					})
				);
			})
		)
	);

	reloadAnalytics$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(
					MonitoringActions.changeAnalyticsPagination,
					MonitoringActions.changeAnalyticsPeriod
				),
				tap(() => {
					this.monitoringFacade.getAllEvents({deviceId: ''});
				})
			);
		},
		{dispatch: false}
	);

	getOrders$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MonitoringActions.getAllOrders),
			switchMap(result => {
				return this.monitoringService
					.getAllOrdersForDevices({
						deviceId: result.deviceId,
						from: result?.from,
						to: result?.to
					})
					.pipe(
						map(response => {
							return MonitoringActions.getAllOrdersSucc({
								response: response
							});
						}),
						catchError(error => {
							return of(MonitoringActions.getAllOrdersFailed({error}));
						})
					);
			})
		)
	);
	analyticsVenuesOrdersAndOnline$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MonitoringActions.getAnalyticsForOnlineAndOrders),
			switchMap(result => {
				console.log('getAnalyticsForOnlineAndOrders', result);
				return this.monitoringService
					.getOnlineAndOrdersStatistic(result.venues, false)
					.pipe(
						map(response => {
							return MonitoringActions.getAnalyticsForOnlineAndOrdersSucc({
								response: response
							});
						}),
						catchError(error => {
							return of(
								MonitoringActions.getAnalyticsForOnlineAndOrdersFailed({
									error
								})
							);
						})
					);
			})
		)
	);
	updateDeviceType$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MonitoringActions.updateDeviceType),
			switchMap(result => {
				console.log('RESULT', result);
				return this.monitoringService
					.updateDeviceType({
						deviceId: result.deviceId,
						deviceType: result.deviceType
					})
					.pipe(
						map(response => {
							return MonitoringActions.updateDeviceSuccess({
								device: response
							});
						}),
						catchError(error => {
							return of(
								MonitoringActions.updateDeviceError({
									error
								})
							);
						})
					);
			})
		)
	);
	constructor(
		private actions$: Actions,
		private monitoringService: MonitoringService,
		private monitoringFacade: MonitoringFacade
	) {}
}
